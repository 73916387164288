/** @jsxImportSource theme-ui */
import * as React from 'react'
import {Link} from "gatsby"
import { COLOR, TYPOGRAPHY_TYPE } from '@ndlib/component-library'
import jesus from "./jesus.png"

const HesburghBranding = () => {
  return (
    <hgroup 
      sx={{
        clear: 'both',
        backgroundColor: COLOR.ND_BLUE,
        backgroundImage: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAUCAYAAADPym6aAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABV0RVh0Q3JlYXRpb24gVGltZQA0LzI3LzE3jUjJYgAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNui8sowAAABFSURBVEiJ7dLRDQAgDELBZ9K1HVy/XKKmhHATQGABGwMFnOkQHQq40yE6WBXJtZRYFcm1lGQRNVZFci0lVkVyLSVZJD55jH0SVE/NqmAAAAAASUVORK5CYII=)",
        backgroundPosition: "0 0",
        backgroundRepeat: "repeat-x",
        height: "100px",
        overflow: 'hidden',
      }}>
        <div
          sx={{
            backgroundImage: `url(${jesus})`,
            backgroundSize: "contain",
            backgroundPosition: "100% 50%",
            backgroundRepeat: "no-repeat",
            ml: "auto",
            mr: "auto",
            pl: "0",
            pr: "0",
            maxWidth: "1200px",
            height: "100%",
            '@media screen and (max-width: 780px)': {
              backgroundPosition: 'center',
            },
          }}>
          <h1
          sx={{
            fontFamily: "GPCMed, sans-serif",
            fontWeight: "400",
            m: 0,
            pl: '12px',
            '@media screen and (min-width: 1212px)': {
              pl: '0',
            },
            '@media screen and (max-width: 780px)': {
              pl: '0',
            },
          }}><Link 
          to='/' 
          title='Hesburgh Library Home' sx={{
            color: COLOR.TEXT_ON_PRIMARY,
            p: "32px 0 30px",
            fontSize: "38px",
            fontFamily: "GPCMed, sans-serif",
            textTransform: "uppercase",
            textDecoration: "none",
            lineHeight: "1em",
            display: "flex",
            gap: "7px",
            '@media screen and (max-width: 780px)': {
              fontSize: '36px',
              gap: '7px',
              paddingLeft: 0,
              justifyContent: 'center',
              'em': {
                display: 'block',
              }
            },
            "em": {
              color: COLOR.SECONDARY,
              fontStyle: "normal",
            }
          }}>Hesburgh <em>Libraries</em></Link></h1>
        </div>
      </hgroup>
  )
}


export default HesburghBranding
